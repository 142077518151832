@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
.title {
    font-family:'Open Sans', sans-serif;
    font-size: 20px;
    color: #CF112D;
    text-align: center;
    text-justify: inter-word;
    font-style: normal;
    font-weight: 600;
    margin-top: 1%;
    margin-bottom: 1%;
}
.space {
    margin: 3%;
    padding: 0;
}
.div{
    width: 50%;
    display: flow-root;
    top: 10%;
    left: 100%;

    /*width: 100%;
    display: "flex";
	align-items: "center";
    justify-content: center;
    position: absolute;
    margin: auto;
    border: 1pt solid ;*/
}